import { Component, Inject } from '@angular/core';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';
import { MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefreshRateDialogComponent } from '../refresh-rate-dialog/refresh-rate-dialog.component';

@Component({
  selector: 'app-pop-up-dialog',
  standalone: true,
  imports: [MaterialModule, CommonModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  templateUrl: './pop-up-dialog.component.html',
  styleUrl: './pop-up-dialog.component.scss'
})
export class PopUpDialogComponent {

  title: string = '';
  content: string = '';
  btnLabel: string = '';

  constructor(public dialogRef: MatDialogRef<RefreshRateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.content = data.content;
    this.btnLabel = data.btnLabel;
  }
}

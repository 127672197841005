import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';
import { Analytics } from '@angular/fire/analytics';
import { Performance } from '@angular/fire/performance';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {
  supportEmail = 'atm@travelmoneygroup.com';

  private analytics: Analytics = inject(Analytics);
  private performance: Performance = inject(Performance);
  
  constructor(private router: Router) {}

  navigateToHome()
  {
    this.router.navigateByUrl('home');
  }

}

import { Injectable } from '@angular/core';
import { CheckoutStatusResponse } from '../models/checkout-status-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaymentRequest } from '../models/payment-request';
import { PaymentResponse } from '../models/payment-response';
import { AdyenCheckoutStatusResponse } from '../models/adyen-checkout-status-response';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getCheckoutStatus(transactionId: string) {    
    return this.http.get<CheckoutStatusResponse>(environment.apiUrl + '/checkout/status?paymentId=' + transactionId);
  }

  getAdyenCheckoutStatus(sessionId: string, sessionResult: string, transactionId: string, originatorId: string) {    
    return this.http.get<AdyenCheckoutStatusResponse>(environment.apiUrl + '/tmoz/checkout/status?sessionId=' 
    + sessionId + '&sessionResult=' + sessionResult + '&transactionId=' + transactionId + '&originatorId=' + originatorId);
  }

  processPayment(pr: PaymentRequest) {    
    return this.http.post<PaymentResponse>(environment.apiUrl + '/tmoz/checkout',pr);
  }

}

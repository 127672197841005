import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpLookupService {

  constructor(private http:HttpClient) { }  
  public getIPAddress() {  
    return this.http.get("https://api.ipify.org/?format=json");  
  }  

  public getIPAddressAWS() {  
    return this.http.get("https://checkip.amazonaws.com/",{responseType: 'text'});  
  }  
}

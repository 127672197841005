import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map, catchError, of } from 'rxjs';
import { TmozExchangeRateRequest } from '../models/tmoz-exchange-rate-request';
import { TmozExchangeRateResponse } from '../models/tmoz-exchange-rate-response';
import { environment } from '../../environments/environment';
import { TmozImxRateResponse } from '../models/tmoz-imx-rate-response';

@Injectable({
  providedIn: 'root'
})
export class RatesService {

  constructor(private http: HttpClient) { }

  // getFxRate(baseCurrency: string, quoteCurrency: string)
  // {    
  //   let todayDate = new Date();
  //   let yestedayrDate = new Date();
  //   yestedayrDate.setDate(yestedayrDate.getDate()-1)

  //   console.log(todayDate)
  //   console.log(yestedayrDate)

  //   let todayDateStr = todayDate.toISOString();
  //   let yesterDateStr = yestedayrDate.toISOString();

  //   let todayDateStrformatted = todayDate.toISOString().substring(0, todayDateStr.indexOf('T'));
  //   let yesterdayDateStrformatted = yestedayrDate.toISOString().substring(0, todayDateStr.indexOf('T'));

  //   console.log(todayDateStr.substring(0, todayDateStr.indexOf('T')))
  //   console.log(yesterDateStr.substring(0, yesterDateStr.indexOf('T')))

  //   return this.http.get<any>(
  //     'https://fxds-public-exchange-rates-api.oanda.com/cc-api/currencies?base='
  //     + baseCurrency + '&quote=' + quoteCurrency + '&data_type=general_currency_pair&start_date=' + yesterdayDateStrformatted + '&end_date=' + todayDateStrformatted)
  // }

  getFxRate(quoteCurrency: string)
  {    
    return this.http.get<TmozImxRateResponse>(environment.apiUrl + '/tmoz/checkout/rates')
      .pipe(
        tap((res: TmozImxRateResponse) => console.log('TMOZ Rates:',res)),
        map((res: TmozImxRateResponse) => res.data.rates.find((r) => r.quoteCurrency === quoteCurrency)),
        catchError((err, caught) => of(null))
      );
  }

  getAllFxRates()
  {    
    return this.http.get<TmozImxRateResponse>(environment.apiUrl + '/tmoz/checkout/rates');
  }
}

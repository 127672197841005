import { Rate } from './../../models/tmoz-imx-rate-response';
import { IpLookupService } from './../../services/ip-lookup.service';
import { OrderService } from './../../services/order.service';
import { Component, Inject, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RatesService } from '../../services/rates.service';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule, DOCUMENT, formatDate } from '@angular/common';
import { AbstractControl, FormControl, FormGroupDirective, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { PaymentRequest } from '../../models/payment-request';
import { PaymentResponse } from '../../models/payment-response';
import { MatDialog } from '@angular/material/dialog';
import { catchError, debounceTime, distinctUntilChanged, interval, retry, throwError, timer } from 'rxjs';
import { CurrencyAmount } from '../../models/currency-amount';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Analytics, Item, logEvent } from '@angular/fire/analytics';
import { Performance } from '@angular/fire/performance';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAnglesDown, faUser, faEnvelope, faRotate, faAddressCard, faUserPlus, faPhone, faCalendarDays, faCartShopping, faTrashCan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { PopUpDialogComponent } from '../pop-up-dialog/pop-up-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent {
  id: string = '';
  ipAddress: string = '';
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  errorStateMatcher = new InstantErrorStateMatcher();

  // Rates configs
  // amountOptions: any[] = [ 20.00, 40.00, 60.00, 80.00, 100.00 ]

  baseCurrency = 'AUD';
  // baseCurrencyAmount = 0;
  rates: Rate[] = [];
  rate: number = 0;

  // currencyOptions: CurrencyAmount[] = [
  //   {country: 'EU', currency: 'EUR', amountsAvailable: [100.00, 200.00, 300.00, 400.00, 500.00], selectedAmount: 100.00},
  //   {country: 'GB', currency: 'GBP', amountsAvailable: [100.00, 200.00, 300.00, 400.00, 500.00], selectedAmount: 200.00},
  //   {country: 'NZ', currency: 'NZD', amountsAvailable: [100.00, 200.00, 300.00, 400.00, 500.00], selectedAmount: 300.00},
  //   {country: 'US', currency: 'USD', amountsAvailable: [100.00, 200.00, 300.00, 400.00, 500.00], selectedAmount: 400.00}
  // ];
  // selectedCurrencyOption: CurrencyAmount = this.currencyOptions[3];

  currencyAmounts: CurrencyAmount[] = [
    {
      country: 'EU',
      currency: 'EUR',
      denom: 20.0,
      minimumAmount: 20.0,
      selectedAmount: 100.0,
    },
    {
      country: 'GB',
      currency: 'GBP',
      denom: 20.0,
      minimumAmount: 20.0,
      selectedAmount: 100.0,
    },
    {
      country: 'NZ',
      currency: 'NZD',
      denom: 50.0,
      minimumAmount: 50.0,
      selectedAmount: 100.0,
    },
    {
      country: 'US',
      currency: 'USD',
      denom: 20.0,
      minimumAmount: 20.0,
      selectedAmount: 100.0,
    },
  ];
  selectedCurrencyAmount: CurrencyAmount = this.currencyAmounts[3];

  // User input config
  quoteCurrencyAmountControl = new FormControl(
    this.selectedCurrencyAmount.minimumAmount,
    [Validators.min(0), Validators.max(1000)]
  );
  baseCurrencyAmountControl = new FormControl(0, [Validators.max(999)]);

  firstnameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(80),
  ]);
  lastnameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(80),
  ]);
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  phoneControl = new FormControl('', [Validators.required, ValidatePhone]);
  dobControl = new FormControl<Date | null>(null, [Validators.required]);
  tcControl = new FormControl(false, [Validators.requiredTrue]);

  // UI configs
  payLoading = false;
  flagStyle = '';
  rateLoading = false;
  addedToCart = false;
  amountRoundDownWarning = false;
  amountRoundUpWarning = false;

  minDate: Date = new Date(1900, 1, 1);
  maxDate: Date = new Date(2020, 12, 31);
  startDate = new Date(1990, 0, 1);

  // Analytics
  private analytics: Analytics = inject(Analytics);
  private performance: Performance = inject(Performance);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public ratesService: RatesService,
    public orderService: OrderService,
    public ipLookupService: IpLookupService,
    @Inject(DOCUMENT) private document: any,
    public dialog: MatDialog,
    public library: FaIconLibrary
  ) {
    console.log('Document Location', this.document.location);
    console.log('Timezone: ', this.timeZone);

    library.addIcons(
      faAnglesDown,
      faUser,
      faRotate,
      faAddressCard,
      faUserPlus,
      faEnvelope,
      faPhone,
      faCalendarDays,
      faCartShopping,
      faTrashCan,
      faTriangleExclamation
    );

    this.ipLookupService.getIPAddressAWS().subscribe((res: string) => {
      this.ipAddress = res.trim();
      console.log('Ip Address (AWS): [' + this.ipAddress + ']');
    });

    this.refreshRates();
    interval(900000).subscribe(async () => {
      this.dialog.closeAll();
      console.log('Updating rates');
      this.openRateRefreshDialog('0ms', '0ms');
    });
  }

  ngOnInit() {
    console.log('ngOnInit');

    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.id = params['id'];
      console.log('ID', this.id);
    });

    this.onCurrencySelected();

    this.quoteCurrencyAmountControl.valueChanges.pipe(
        debounceTime(2000),
        distinctUntilChanged()
      ).subscribe(newMessage => {
        console.log("Value input", newMessage);
        // logEvent(this.analytics, 'view_quantity', {
        //   quantity: this.selectedCurrencyAmount.selectedAmount,
        // });
      });
  }

  // setLanguageFlag() {
  //   this.flagStyle = 'flag-style fi fi-' + this.selectedCurrencyAmount.country.toLocaleLowerCase();
  //   console.log('flagStyle',this.flagStyle);
  // }

  onCurrencyListOpened(event: boolean) {
    console.log("Currency list opened: " + event);

    // If currency list presented, trigger view item list
    if(event){
      let itemList: Item[] = [];
      this.currencyAmounts.forEach((ca, i) => {
        itemList.push({
            item_id: ca.currency,
            item_name: ca.currency,
            item_category: 'FX',
            item_category2: ca.currency,
            index: i,
            quantity: ca.selectedAmount,
            price: this.rates.find( (value) => value.quoteCurrency === ca.currency )?.rate ?? 0,
          })
      });
      console.log("itemList: ", itemList);
      logEvent(this.analytics, 'view_item_list', 
        { item_list_id: "currency_list", item_list_name: "Currency List", items: itemList }
      );
    }
  }

  onCurrencySelected() {
    // Set correct flag
    this.flagStyle = 'flag-style fi fi-' + this.selectedCurrencyAmount.country.toLocaleLowerCase();
    console.log('flagStyle', this.flagStyle);

    // Update quote currency
    console.log( 'this.selectedCurrencyAmount.selectedAmount', this.selectedCurrencyAmount.selectedAmount );
    this.quoteCurrencyAmountControl.setValue( this.selectedCurrencyAmount.selectedAmount );
    this.quoteCurrencyAmountControl.clearValidators();
    this.quoteCurrencyAmountControl.setValidators([ Validators.required, Validators.min(this.selectedCurrencyAmount.minimumAmount), ]);
    this.quoteCurrencyAmountControl.updateValueAndValidity();
    this.baseCurrencyAmountControl.markAsTouched();

    // Select the rate
    this.setSelectedRate();

    // Update base currency
    this.updateBaseAmount();

    // send analytics data
    logEvent(this.analytics, 'view_item_list', { 
      item_list_id: "currency_list",
      item_list_name: "Currency List",
      items: [{
        item_id: this.selectedCurrencyAmount.currency,
        item_name: this.selectedCurrencyAmount.currency,
        item_category: 'FX',
        item_category2: this.selectedCurrencyAmount.currency,
        index: this.currencyAmounts.findIndex(ca => ca.currency == this.selectedCurrencyAmount.currency),
        quantity: this.selectedCurrencyAmount.selectedAmount,
        price: this.rate,
      }]}
    );

  }

  refreshRates() {
    console.log('Updating base rate');
    this.rateLoading = true;
    this.ratesService
      .getAllFxRates()
      .pipe(
        retry({
          count: 3,
          delay: (err, retryCount) => {
            console.log('delay', err);
            return timer(1000 * (retryCount * retryCount));
          },
        }),
        catchError((error: HttpErrorResponse) => {
          console.log('catchError', error);
          this.openRateRetryDialog('0ms', '0ms');
          return throwError(() => error);
        })
      )
      .subscribe((response) => {
        console.log('Rate response', response);

        if (response == null) {
          this.openRateRetryDialog('0ms', '0ms');
          return;
        }
        console.log('Rates available');
        this.rates = response?.data.rates!;
        this.setSelectedRate();
        // this.setLanguageFlag();
        this.updateBaseAmount();
        this.rateLoading = false;
      });
  }

  // updateBaseCurrency(){
  //   console.log("Updating base rate");
  //   this.rateLoading = true;
  //   this.ratesService.getFxRate(this.selectedCurrencyAmount.currency)
  //   .pipe(
  //     retry({
  //       count: 3,
  //       delay: (err, retryCount) => {
  //         console.log('delay',err);
  //         return timer(1000 * (retryCount*retryCount))
  //       }}),
  //     catchError((error: HttpErrorResponse) => {
  //       console.log('catchError',error);
  //       this.openRateRetryDialog('0ms', '0ms');
  //       return throwError(() => error);
  //     }))
  //   .subscribe(rate => {
  //     console.log('rate',rate);

  //     if(rate == null) {
  //       this.openRateRetryDialog('0ms','0ms');
  //       return;
  //     }
  //     console.log('Rate available');
  //     this.rate = rate?.rate!;
  //     this.updateBaseAmount();
  //     this.setLanguageFlag();
  //     this.rateLoading = false;
  //   });
  // }

  onAmountEntered(event: any) {
    console.log('Event', event);
    console.log(
      'quoteCurrencyAmountControl',
      this.quoteCurrencyAmountControl.value
    );
    this.updateBaseAmount();
  }

  onAddToCart() {
    let remainder = this.quoteCurrencyAmountControl.value! % this.selectedCurrencyAmount.denom;
    console.log('Remainder', remainder);

    this.amountRoundDownWarning = false;
    this.amountRoundUpWarning = false;

    if (remainder > 0) {
      let numNotes = Math.floor(
        this.quoteCurrencyAmountControl.value! / this.selectedCurrencyAmount.denom
      );
      console.log('Number of notes', numNotes);
      this.quoteCurrencyAmountControl.setValue(
        (numNotes + 1) * this.selectedCurrencyAmount.denom
      );
      this.updateBaseAmount();

      if (this.baseCurrencyAmountControl.invalid) {
        this.quoteCurrencyAmountControl.setValue(
          numNotes * this.selectedCurrencyAmount.denom
        );
        this.updateBaseAmount();
        this.amountRoundDownWarning = true;
      } else {
        this.amountRoundUpWarning = true;
      }
    }

    this.addedToCart = true;

    logEvent(this.analytics, 'add_to_cart', {
      currency: this.baseCurrency,
      value: this.baseCurrencyAmountControl.value!,
      items: [
        {
          item_name: this.selectedCurrencyAmount.currency,
          item_category: 'FX',
          item_category2: this.selectedCurrencyAmount.currency,
          index: this.currencyAmounts.findIndex(ca => ca.currency == this.selectedCurrencyAmount.currency),
          quantity: this.quoteCurrencyAmountControl.value!,
          price: this.rate,
        },
      ],
    });
  }

  onRemoveFromCart() {
    this.addedToCart = false;
    logEvent(this.analytics, 'remove_from_cart', {
      currency: this.baseCurrency,
      value: this.baseCurrencyAmountControl.value!,
      items: [
        {
          item_name: this.selectedCurrencyAmount.currency,
          item_category: 'FX',
          item_category2: this.selectedCurrencyAmount.currency,
          index: this.currencyAmounts.findIndex(ca => ca.currency == this.selectedCurrencyAmount.currency),
          quantity: this.quoteCurrencyAmountControl.value!,
          price: this.rate,
        },
      ],
    });
  }

  setSelectedRate() {
    if (this.rates == null) return;
    this.rate =
      this.rates.find(
        (value) => value.quoteCurrency === this.selectedCurrencyAmount.currency
      )?.rate ?? 0;
  }

  updateBaseAmount() {
    if (this.selectedCurrencyAmount.selectedAmount == 0) {
      this.baseCurrencyAmountControl.setValue(0);
      return;
    }
    let baseCurrencyAmount = Number(
      ((1 / this.rate) * this.quoteCurrencyAmountControl.value!).toFixed(2)
    );

    this.baseCurrencyAmountControl.setValue(baseCurrencyAmount);
    this.baseCurrencyAmountControl.updateValueAndValidity();
    this.baseCurrencyAmountControl.markAsTouched();
  }

  async openRateRefreshDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): Promise<void> {
    let dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: {
        title: 'Rate Updated',
        content: 'Your rates have been updated',
        btnLabel: 'OK',
      },
      width: '300px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed');
      this.refreshRates();
    });
  }

  async openRateRetryDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): Promise<void> {
    let dialogRef = this.dialog.open(PopUpDialogComponent, {
      data: {
        title: 'Rate offline',
        content: 'Unable to retreive the latest rates',
        btnLabel: 'Refresh page',
      },
      width: '300px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed');
      window.location.reload();
    });
  }

  payOrder() {
    console.log('Pay order');
    this.payLoading = true;

    console.log('email', this.emailControl);
    const pr: PaymentRequest = {
      host: this.document.location.origin,
      originatorId: environment.originatorId,
      type: this.selectedCurrencyAmount.currency,
      amount: this.quoteCurrencyAmountControl.value!,
      firstname: this.firstnameControl.value!.trim(),
      lastname: this.lastnameControl.value!.trim(),
      email: this.emailControl.value!.trim(),
      phoneNumber: '+61' + this.phoneControl.value!.trim(),
      dob: formatDate(this.dobControl.value!, 'yyyy-MM-dd', 'en_AU'),
      ipAdress: this.ipAddress,
      timeZone: this.timeZone,
    };

    console.log('PaymentRequest', pr);

    this.orderService.processPayment(pr).subscribe({
      next: (res: PaymentResponse) => {
        console.log('res', res);

        logEvent(this.analytics, 'begin_checkout', {
          currency: this.baseCurrency,
          value: this.baseCurrencyAmountControl.value!,
          items: [
            {
              item_name: this.selectedCurrencyAmount.currency,
              item_category: 'FX',
              item_category2: this.selectedCurrencyAmount.currency,
              index: this.currencyAmounts.findIndex(ca => ca.currency == this.selectedCurrencyAmount.currency),
              price: this.rate,
              quantity: this.quoteCurrencyAmountControl.value!,
            },
          ],
        });

        window.location.href = res.url;
      },
      error: (error) => {
        console.log('error', error);
        this.payLoading = false;
      },
    });
  }
}

function ValidatePhone(control: AbstractControl): {[key: string]: any} | null  {
  if (control.value) {
    console.log('isValidPhoneNumber', isValidPhoneNumber('+61'+control.value, 'AU'));
    if(!isValidPhoneNumber('+61' + control.value, 'AU') ){
      return {invalidPhoneNumber: true};
    }
  }
  return null;
}


// see: https://stackoverflow.com/questions/54349574/angular-material-mat-error-shows-only-when-from-control-was-touched
export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm | null): boolean {
    return control! && control.invalid && (control.dirty || control.touched);
  }
}

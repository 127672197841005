<div class="container">
    <div class="content">
        <div class="title-container">
            <img src="../../../assets/images/TMOZ Logo - Centered - Reversed - NoHammer-NoTagline.png" alt="Travel Money OZ" />
        </div>
        <div class="top-banner">
            <img src="../../../assets/images/ATM Web Banner Background.png" alt="Travel Money OZ" />
            <!-- <div class="top-banner-curved-edge"></div> -->
        </div>

        <div class="page-content">
            <div class="message-container">
                <span class="message-title-text">Payment Cancelled</span>
            </div>

            <div class="success-text-container">
                <p class="personal-field-title">Your payment was cancelled.</p>
                <p class="personal-field-title">Click on the button below to place a new order.</p>
            </div>
    
            <div class="btn-container">
                <button class="btn-style" mat-raised-button color="custom-color" (click)="navigateToHome()">
                    New Order
                </button>
            </div>

            <p class="support-text">For assistance, reach out to us at <a style="text-decoration:none" href = "mailto: {{supportEmail}}">{{supportEmail}}</a></p>
        </div>
    </div>
</div>